<template>
  <div>
    <section class="section" id="start_page_main_section">
      <div class='container'>
        <div class='has-text-centered'>   
          <h1 class="title" v-html="$t('start_component.title')"></h1>
          <p  class="subtitle" v-html="$t('start_component.subtitle')"></p>
          <div class="steps-container">
            <p>
              <ol class="has-text-left">
                <ul v-html="$t('start_component.step_1')"></ul>
                <ul v-html="$t('start_component.step_2')"></ul>
                <ul v-html="$t('start_component.step_3')"></ul>
              </ol>	
            </p>
          </div>
          <div>
            <a id="ok_go_button" class="button is-primary" v-on:click="handle_start_button()">Ok go !</a>
          </div>
        </div>
      </div>
    </section>
    <section class="section goedgemerkt_logo_container" >
      <div class='container has-text-centered'>
      
      <!-- <div class="level is-mobile">
        <div class="level-item">
          <p>Powered by:</p>
        </div>
        <div class="level-item">
          <div class="logo_goedgemerkt"></div>
        </div>
      </div> -->
        <span>Powered by:</span>
        <div class="logo_goedgemerkt" v-on:click="goto_gg">
          <img v-if='locale=="be_nl"|locale=="nl"' src="/goedgemerkt_materiaal/logo_goedgemerkt.svg" alt="logo goedgemerkt">
          <img v-if='locale=="be_fr"|locale=="fr"' src="/goedgemerkt_materiaal/logo_bienmarquer.svg" alt="logo bienmarquer">
          <img v-if='locale=="de"' src="/goedgemerkt_materiaal/logo_gutmarkiert.svg" alt="logo gutmarkiert">
        </div>
            
      </div>
    </section>
    
  </div>
</template>

<script>
import {event_bus} from '../main' 
export default {
  metaInfo() {
    // metaInfo is an object. During prerendering, an the pages are generated for both the stardard route 
    // and the fr route. 
    var return_object={
      titleTemplate: '%s',     
    }
    if(this.$route.path=="/fr"){
      // Franse SEO stuff
      return_object.title='Prénoms personnalisés | Names I Like'
      return_object.meta=[
        { charset: 'utf-8' },
        { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" },
        { name: 'google', content: 'notranslate' },
        { name: 'google-site-verification', content: 'onWYxFnSJqLMzGgtpht5CWBMGoRJQmx9dbt1xjwFGL8' },
        { name: 'lang', content: 'nl' },
        { name: 'description', content: 'Trouvez un prénom qui vous plaît à l\'aide d\'un algorithme malin.' },                             
        { name: 'title', content: 'Prénoms personnalisés | Names I Like' },
        { property: 'og:title', content: 'Prénoms personnalisés | Names I Like' },  
        { property: 'og:description', content: 'Trouvez un prénom qui vous plaît à l\'aide d\'un algorithme malin.'},   
        { property: 'og:url', content: 'https://www.namesilike.com/fr' },   
        { property: 'og:locale', content: 'fr' },   
        ]
    }else{
      // Default Nederlandse SEO stuff
      return_object.title='Babynamen op maat | Names I Like', 
      return_object.meta= [
        { charset: 'utf-8' },
        { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" },
        { name: 'google', content: 'notranslate' },
        { name: 'google-site-verification', content: 'onWYxFnSJqLMzGgtpht5CWBMGoRJQmx9dbt1xjwFGL8' },
        { name: 'lang', content: 'nl' },
        { name: 'description', content: 'Met ons algoritme was naar babynamen zoeken nog nooit zo leuk! We verzamelen alle babynaam stastieken en geven persoonlijke suggesties!' },                             
        { name: 'title', content: 'Babynamen op maat | Names I Like' },
        { property: 'og:title', content: 'Babynamen op maat | Names I Like' },  
        { property: 'og:description', content: 'Met ons algoritme was naar babynamen zoeken nog nooit zo leuk! We verzamelen alle babynaam stastieken en geven persoonlijke suggesties!' },   
        { property: 'og:url', content: 'https://www.namesilike.com' },   
        { property: 'og:locale', content: 'nl' }  
        ]     
    }
    return return_object
  }, 
  i18n:{
    messages:{
      be_nl:{
        app_component: { 
          language_meta:'nl-BE',
          title: 'Babynamen op maat | Names I Like',
          description: 'Met ons algoritme was naar babynamen zoeken nog nooit zo leuk! We verzamelen alle babynaam stastieken en geven persoonlijke suggesties!',
        },
        start_component: { 
          title: 'Babynamen op maat',
          subtitle: 'Vind met ons <b>uitgekiend algoritme</b> je favoriete babynaam.',
          step_1:'1. Kies het <b>geslacht</b>',
          step_2:'2. Beantwoord de <b>vragen</b>.',
          step_3:'3. Bekijk de <b>suggesties</b>!',
        },
      },
      nl:{
        app_component: { 
          language_meta: 'nl-NL',
          title: 'Babynamen op maat | Names I Like',
          description: 'Met ons algoritme was naar babynamen zoeken nog nooit zo leuk! We verzamelen alle babynaam stastieken en geven persoonlijke suggesties!',
        },
        start_component: { 
          title: 'Babynamen op maat',
          subtitle: 'Vind met ons <b>uitgekiend algoritme</b> je favoriete babynaam.',
          step_1:'1. Kies het <b>geslacht</b>',
          step_2:'2. Beantwoord de <b>vragen</b>.',
          step_3:'3. Bekijk de <b>suggesties</b>!',
        },
      },      
      be_fr:{
        app_component: { 
          language_meta:'fr-BE',
          title: 'Prénoms personnalisés | Names I Like',
          description: 'Trouvez un nom de bébé qui vous plaît à l\'aide destatistiques et d\'un algorithme malin.',
        },
        start_component: { 
          title: 'Prénoms personnalisés',
          subtitle: 'Trouvez un prénom qui vous plaît à l\'aide d\'un <b>algorithme malin</b>.',
          step_1:'1. Choisissez <b>le sexe</b>',
          step_2:'2. Répondez aux <b>questions</b>',
          step_3:'3. Regardez <b>les resultats</b>',
        },
      },
      fr:{
        app_component: { 
          language_meta:'fr-BE',
          title: 'Prénoms personnalisés | Names I Like',
          description: 'Trouvez un nom de bébé qui vous plaît à l\'aide destatistiques et d\'un algorithme malin.',
        },
        start_component: { 
          title: 'Prénoms personnalisés',
          subtitle: 'Trouvez un prénom qui vous plaît à l\'aide d\'un <b>algorithme malin</b>.',
          step_1:'1. Choisissez <b>le sexe</b>',
          step_2:'2. Répondez aux <b>questions</b>',
          step_3:'3. Regardez <b>les resultats</b>',
        },
      }, 
      de:{
        app_component: {
          language_meta:'de',
          title: 'Passenden Babynamen | Names I Like',
          description: 'Finden Sie mit unserem ausgereiften Algorithmus Ihren Lieblingsnamen.',
        },
        start_component: { 
          title: 'Passenden Babynamen',
          subtitle: 'Finden Sie mit unserem <b>ausgereiften Algorithmus</b> Ihren Lieblingsnamen.',
          step_1:'1. Wähle das <b>Geschlecht</b>',
          step_2:'2. Beantworte die <b>Fragen</b>.',
          step_3:'3. Sehe die Beispiele <b>Beispiele</b>!',
        },
      }     
    }
  },
  data() {
    return {
      locale:''
    };
  },
  mounted(){
  },
  created(){
    this.locale = this.$i18n.locale;
    event_bus.$on('language_set', function(locale){
      this.locale = locale
    }.bind(this));
    // Show language buttons again
    this.$parent.navbar.show_language_buttons = true
  },
  methods:{
    goto_gg(){
      // Google analytics
      this.$ga.event({
        eventAction:'0_click_to_gg', 
        eventCategory:'building_model',
      })
      fbq('trackCustom', '0_click_to_gg');
      this.$parent.$parent.register_click('user_going_to_gg', 
        {'from': 'landing_page'})
      window.open('https://www.goedgemerkt.nl', '_blank');
    },
    handle_start_button(){
      // Create model id
      this.$parent.session_parameters.model_id = this.make_id()
      // Register action on GA and SQL
      this.$ga.event({
        eventAction:'0_ok_go', 
        eventCategory:'building_model',
      })
      fbq('track', 'PageView');      
      fbq('trackCustom', '0_ok_go');
      // Register click
      this.$parent.register_click('start_button', '');
      // Emit an event. This is used inside the recommender system component to reset it
      event_bus.$emit('start_button_clicked')
      // Go to recommender-system component
      this.$router.push({ path: 'recommender-system' })
      // Hide Navbar
      this.$parent.navbar.show=false;
    },
    make_id(){
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for( var i=0; i < 20; i++ )
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped >
@import '../../public/config.scss';

#start_page_main_section{
  padding-top:6rem;
}
p{
  margin-bottom: 1em;
}
.container{
  max-width: 25em;
}
.goedgemerkt_logo_container{
  padding-top:0em;
}
.logo_goedgemerkt{
  
  width:60%;
  max-width:300px;
  margin:auto;
  max-height:200px;
  /* margin-top:-1em; */
  /* background-image: url(); */
  /* background-size:contain; */
  /* background-repeat: no-repeat; */
}
.button {
  margin: 1em;
}
#ok_go_button{
  box-shadow: 0 2px 4px 0 $green-button;
  border-radius:999px;
  padding: 0.5em 1.5em;
  height:auto;
  font-size: 1.3em;
  margin-top:-1.5em;
}

ul{
  margin-bottom:0.5em;
}
.steps-container {
    margin-bottom: 0;
    background: $rose;
    padding: 1em 1em 2em 2em;
    box-shadow: 1px 1px 8px 0 $dark-rose;
    font-size:0.9em;
}
    /* @media (max-width: 540px) {
        padding-bottom: 2em;
    } */
</style>
