<template>
  <div>
    <section class="section" id="header-parent">
      <div class='container'>
        <div class='has-text-centered' id="header">
            <p>
              <span id="vraag_title">{{$t('multiple_choice_component.question')}}</span>
              <span id="vraag_sequence">{{question}}/20</span>
            </p>
            
        </div>
      </div>  
    </section>
    <section class="section" id="choices_container">
      <div class='container'>
        <div class='has-text-centered'>
            <div class="name_choice_buttons">
              <ul>
                <p><a class="button" v-on:click="handle_name_click(0)">{{active_names[0]}}</a></p>
                <p><a class="button" v-on:click="handle_name_click(1)">{{active_names[1]}}</a></p>
                <p><a class="button" v-on:click="handle_name_click(2)">{{active_names[2]}}</a></p>
                <p><a class="button" v-on:click="handle_name_click(3)">{{active_names[3]}}</a></p>
              </ul>
            </div>
            <transition name="fade">
              <p class='subtitle' v-if="question==1">{{$t('multiple_choice_component.instruction_1')}}</p>
            </transition>
            <transition name="fade">
              <p class='subtitle' v-if="(question>2) && (question<6)">{{$t('multiple_choice_component.instruction_3')}}</p>
            </transition>
            <!-- <transition name="fade">
              <p class='subtitle' v-if="(question>10) && (question<14)">{{$t('multiple_choice_component.instruction_11')}}</p>
            </transition> -->
          </div>
        </div>
      </section>
  </div>
</template>

<script>
import {event_bus} from '../../main' 
export default {
  i18n:{
    messages:{
      be_nl:{
        multiple_choice_component:{
          question: 'Vraag',
          instruction_1: 'Welke naam vind je het beste?',
          instruction_3: 'Beslis snel, volg je buikgevoel',
          instruction_11: 'In de helft, nog 20 seconden',
        }
      },
      nl:{
        multiple_choice_component:{
          question: 'Vraag',
          instruction_1: 'Welke naam vind je het beste?',
          instruction_3: 'Beslis snel, volg je buikgevoel',
          instruction_11: 'In de helft, nog 20 seconden',
        }
      },      
      be_fr:{
        multiple_choice_component:{
          question: 'Question',
          instruction_1: 'Quel nom préférez-vous?',
          instruction_3: 'Ne vous-en faites pas si le choix est parfois difficile.',
          instruction_11: 'A la moitié, encore 20 secondes',
        }
      },
      fr:{
        multiple_choice_component:{
          question: 'Question',
          instruction_1: 'Quel nom préférez-vous?',
          instruction_3: 'Ne vous-en faites pas si le choix est parfois difficile.',
          instruction_11: 'A la moitié, encore 20 secondes',
        }
      }, 
      de:{
        multiple_choice_component:{
          question: 'Frage',
          instruction_1: 'Welcher Name gefällt Ihnen am besten?',
          instruction_3: 'Entscheide schnell, folgen Sie Ihrem Bauchgefühl',
          instruction_11: 'In der Hälfte weitere 20 Sekunden',
        }
      },            
    }
  },
  data(){
		return {
      locale:'',
      question:0,
      round:0,
      names_list_multiple_choice:[],
      active_names:[],
      names_clicked:{
        // An array for each round
        1:[],
        2:[],
        3:[],
        4:[],
        best_of:[],
      },
      names_clicked_json:[],
		}
  },
  created(){
    this.locale = this.$i18n.locale;
    event_bus.$on('language_set', function(locale){
      this.locale = locale
    }.bind(this));
    // Copy the multiple choice list of names of the parent object for convience
    this.names_list_multiple_choice = this.$parent.names_list_multiple_choice;
    // Initiate the counters
    this.question=1;
    this.question=1;
    this.round=1;
    // Show the first 4 names
    this.active_names = this.names_list_multiple_choice.slice(0,4);
  },
  methods:{
    handle_name_click:function(name_i_clicked){
      // Write selected name away
      var name_clicked = this.active_names[name_i_clicked];
      if(this.question%5!=0) this.names_clicked[this.round].push(name_clicked)
      else this.names_clicked['best_of'].push(name_clicked)
      // Write names away in json format
      for(var i=0; i<this.active_names.length; i++){
        var temp_object = {'q': this.question, 
                          'r': this.round,
                          'n':this.active_names[i],
                          'f':this.active_names[i] != name_clicked ? 'nc' : 'c'};
        this.names_clicked_json.push(temp_object)         
      }
      // Show albums following question. 
      this.question+=1;
      if(this.question==6 || this.question==11 || this.question==16){
        // Increase round
        this.round+=1;
      }
      // Check if we are not at the end. If so, prepare data to return to backend
      if(this.question>20){
        // We are at the end, register with GA
        this.$ga.event({
          eventAction:'2A_last_question_clicked', 
          eventCategory:'building_model',
        })
        fbq('trackCustom', '2A_last_question_clicked');
        // We are at the end, end the process and set data to parent component
        this.$parent.selected_data.multiple_choice_answers = this.names_clicked_json;
        this.$parent.handle_multiple_choice_answers();
        return;
      }
      // Check first if no best of question
      if(this.question%5!=0){
        // Normal question
        var temp_start = 4*(this.question-1) - 4*(this.round-1);
        var temp_end = 4*(this.question-1)+4 - 4*(this.round-1);
        this.active_names = this.names_list_multiple_choice.slice(temp_start, temp_end)
      }else{
        // Best of question
        this.active_names = this.names_clicked[this.round]

      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../../public/config.scss';
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Raleway:300,500,700');
#header-parent {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    margin: 0;
}
#header {
    font-family: 'Josefin Sans', 'Roboto', san-serif;
    color: white;
    position: absolute;
    top: -25em;
    left: 50%;
    width: 35em;
    height: 30em;
    padding-top: 0;
    padding: 0;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    background-color: $green-button;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 $green-button;
}
#header p {
    position: absolute;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    font-weight: bold;
    text-transform: uppercase;
}
#vraag_title{
  display: block;
  font-size: 1.5em;
}
#vraag_sequence{
  display: block;
  font-size:3em;
  margin-top:-0.3em;
}
#choices_container{
  padding-top: 30vh;
}
#choices_container p{
  margin-bottom: 3vh;
}
#choices_container a{
  text-transform: none;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 2em;
  height: auto !important;
  min-width: 11em;
  border-radius: 999px;
  box-shadow: 0 2px 4px 0 $dark-rose;
  padding-top:0.1em;
  padding-bottom:0.1em;
}
#choices_container p:nth-child(even) a {
  color: $dark-indigo;
}
#choices_container p:nth-child(odd) a {
  color: $green-button;
}
#choices_container  .name_choice_buttons{
  margin-bottom:2.5em;
}

</style>
