<template>
  <div>
    <section class="section has-text-centered" >
      <div class="container white_card">
        <p class='subtitle main_description'>Reading your mind...</p>
        <progress class="progress is-info" v-bind:value="progress_bar_fill" max="100"></progress>
        <p class="powered_by" v-html="$t('await_recommendations_component.gg_pub')"></p>
        <div class='image_container'>
          <img src="goedgemerkt_materiaal/afbeelding_voor_awaiting.jpeg"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {event_bus} from '../../main' 

export default {
  i18n:{
    messages:{
      be_nl:{
        await_recommendations_component:{
          powered_by: 'Deze website is u aangeboden door:',
          gg_pub:'Welke naam je ook kiest, <b>naamlabels</b> komen binnenkort van pas...'
        }
      },
      nl:{
        await_recommendations_component:{
          powered_by: 'Deze website is u aangeboden door:',
          gg_pub:'Welke naam je ook kiest, <b>naamlabels</b> komen binnenkort van pas...'
        }
      },      
      be_fr:{
        await_recommendations_component:{
          powered_by: 'Ce site vous est proposé par:',
          gg_pub:'Quel que soit le nom que vous choisissez, des autocollants personnalisés de <b>bienmarquer.fr</b> \
          seront utiles bientôt'
        }
      },
      fr:{
        await_recommendations_component:{
          powered_by: 'Ce site vous est proposé par:',
          gg_pub:'Quel que soit le nom que vous choisissez, des autocollants personnalisés de <b>bienmarquer.fr</b> \
          seront utiles bientôt'
        }
      },  
      de:{
        await_recommendations_component:{
          powered_by: 'Diese Website wird Ihnen angeboten durch:',
          gg_pub:'Welchen Namen Sie auch wählen, <b> Namensaufkleber </ b> werden sich bald als nützlich erweisen ...'
        }
      },            
    }
  },
  mounted(){
    this.locale = this.$i18n.locale;
    event_bus.$on('language_set', function(locale){
      this.locale = locale
    }.bind(this));

    var total_seconds = 10;
    var time_step = 10 //Milliseconds
    var total_steps = total_seconds*1000/time_step
    var percent_step = 100/total_steps
    var fill_progress_bar_timer = setInterval(fill_progress_bar, time_step)  
    var pass_this = this
    function fill_progress_bar() {
      if (pass_this.progress_bar_fill > 100) {
        clearInterval(fill_progress_bar_timer);
      } else {
        pass_this.progress_bar_fill = pass_this.progress_bar_fill+percent_step;
      }
    }  
    
  },
  data: function(){
    return {
      locale:'',
      progress_bar_fill:0,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../../public/config.scss';
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Raleway:300,500,700');

.white_card{
  background: white;
	box-shadow: 1px 1px 8px 0 $dark-rose;
  margin-top: 1em;
  max-width:300px;
}
.main_description{
  padding-top:1em;
}
.powered_by{
  padding:1em;
}
.progress{
  width: 70%;
  height: 0.5rem;
  margin:auto;
}
img{
  width:90%;
  margin:auto;
}
</style>
