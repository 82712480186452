import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props:['data'],
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart({
      labels: this.range(parseInt(this.$parent.$parent.min_year), parseInt(this.$parent.$parent.max_year)),
      datasets: [
        {
          fill: false,
          data: this.data.ts,
          borderColor: '#A9A9A9',
          pointRadius:0,
        }
      ]
    }, 
    {
      maintainAspectRatio:false,
      scales: {
        yAxes:[{
          display:true,
          ticks: {
            min: 0, 
            max: this.$i18n.locale=='be_nl' ? 200 : 300,
            callback: function(value, index, values) {
              if(value==0){
                if((this.$i18n.locale == 'be_nl') || (this.$i18n.locale == 'nl')){
                  return 'zeldzaam'
                }else if((this.$i18n.locale == 'be_fr') || (this.$i18n.locale == 'fr')){
                  return 'rare'
                }else if(this.$i18n.locale == 'de'){
                  return 'selten'
                }
              }else if(value==200){
                if((this.$i18n.locale == 'be_nl') || (this.$i18n.locale == 'nl')){
                  return 'populair'
                }else if((this.$i18n.locale == 'be_fr') || (this.$i18n.locale == 'fr')){
                  return 'populaire'
                }else if(this.$i18n.locale == 'de'){
                  return 'beliebt'
                }
              }else{
                return ''
              }
            }.bind(this)
          }
        }]
      },
      legend:{
        display:false
      },
      title:{
        text:((this.$i18n.locale == 'be_fr') | (this.$i18n.locale == 'fr')) ? 'Popularité': 'Populariteit',
        fontColor: '#4a90e2',
        fontStyle:'bold',
        display:true,
        fontSize:'16',
      },
      layout: {
          padding: {
              left: 10,
              right: 20,
              top: 0,
              bottom: 0
          }
      }
    })
  },
  methods:{
    range(low,hi){
      var list = [];
      for (var i = low; i <= hi; i++) {
          list.push(i);
      }
      return list
      // function rangeRec(low, hi, vals) {
      //    if(low > hi) return vals;
      //    vals.push(low);
      //    return rangeRec(low+1,hi,vals);
      // }
      // return rangeRec(low,hi,[]);
    }
  }
}