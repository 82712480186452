import Vue from 'vue';
import Router from 'vue-router';
import About from './views/About.vue';
import InspirationTool from './views/InspirationTool.vue';
import StatsTool from './views/StatsTool.vue';
import Homepage from './views/Homepage.vue';
import RecommenderSystem from './views/RecommenderSystem.vue';

Vue.use(Router)

const router = new Router({
  mode:'history',
  routes: [
    // Homepage routes
    {
      path: '/',
      name: 'homepage',
      component: Homepage
    },
    {
      path: '/fr',
      name: 'homepage-fr',
      component: Homepage
    },    
    {
      path: '/index.html',
      name: 'homepage-indexhtml',
      component: Homepage
    },
    {
      path: '/babynamen',
      name: 'homepage-be_nl',
      component: Homepage
    },
    {
      path: '/jongensnamen',
      name: 'jongensnamen',
      component: InspirationTool
    },
    {
      path: '/meisjesnamen',
      name: 'meisjesnamen',
      component: InspirationTool
    },    
    // Recommender system routes
    {
      path: '/babynamen-suggesties',
      name: 'babynamen-suggesties',
      component: RecommenderSystem
    },
    {
      path: '/recommender-system',
      name: 'recommender-system',
      component: RecommenderSystem
    },
    // View result components
    { path: '/view-recommendations/:model_id', 
      name: 'view-recommendations',
      component: RecommenderSystem
    },
    // About page routes
    {
      path: '/about',
      name: 'about',
      component: About
    },
    // Stats tool page
    {
      path: '/statistieken',
      name: 'statistieken',
      component: StatsTool
    },    

    // {
    //   path: '/noms-de-bebes',
    //   name: 'recommender-system-fr',
    //   component: RecommenderSystem
    // }
  ]
})

// Make sure that if the user refreshes or lands on another page, that you always go to home
router.beforeEach((to, from, next) => {

  if(to.name=='homepage' || to.name=='view-recommendations'){
    next();
  }
  else if (from.name === null) {
    // Redirect to the homepage
    next('/');
  } else {
    // Continue with the routing
    next();
  }
});

export default router
